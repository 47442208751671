.settingsmodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -40px;
    width: 100%;
    max-width: 650px;
    background-color: white;
    border-radius: 15px;
    text-align: center;
    color: black;
    padding: 22px;
    display: block;
    z-index: 100;
    box-shadow: 0px 0px 6px 2px #cccccc;
}
.settingsmodal .wrapper {
  max-height: 340px;
  overflow: auto;
  clear: both;
  padding-right: 17px;
}
.settingsmodal .cookiesavebtn {
  background: #3665F3;
  margin-top: 22px;
  float: right;
  border: none;
  padding: 8px;
  width: 172px;
  color: white;
}
.settingsmodal .header span {
  font-size: 16px;
  float: left
}
.settingsmodal .descrtiption {
  clear: both;
}
.settingsmodal .descrtiption p {
  margin-top: 28px;
  display: block;
  text-align: justify;
}
.tooglehandler .linkedtext {
  text-align: left;
  margin-bottom: 13px;
}
.tooglehandler p {
  text-align: justify;
  margin-bottom: 13px;
}
.togglewrapper {
  display: flex;
  border-top: 1px solid #EEEEEE;
  padding: 22px 0px;
}
.togglewrapper h1 {
  margin: 0;
  font-size: 18px;
  margin-top: 2px;
  margin-left: 14px;
}
.togglewrapper .switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

/* Hide default HTML checkbox */
.togglewrapper .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.togglewrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.togglewrapper .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.togglewrapper input:checked + .slider {
  background-color: #2196F3;
}

.togglewrapper input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.togglewrapper input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.togglewrapper .slider.round {
  border-radius: 34px;
}

.togglewrapper .slider.round:before {
  border-radius: 50%;
}